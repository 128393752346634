@import "../../globalStyles/component.scss";

.HomeSlide3 {
  width: 100%;
  padding-right: 4.4%;
  padding-top: 52px;
  padding-bottom: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 674px;
  background: url("../../assets/hslide4.png") lightgray 50% / cover no-repeat; /* Default background */

  .box {
    width: 658px;
    padding-left: 73px;
    padding-right: 86px;
    padding-block: 58px;
    position: relative;
    z-index: 4;

    .title {
      position: relative;
      z-index: 4;
      color: $gray1;
      font-family: $font-primary;
      font-size: min(40px, 3.4vw);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 22px;
      @media (max-width: 750px) {
        font-size: 44px;
      }
    }
    .desc {
      position: relative;
      z-index: 4;
      margin-bottom: 12px;
      color: $gray3;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-transform: capitalize;
    }
    .btn {
      all: unset;
      position: relative;
      z-index: 4;

      display: flex;
      align-items: center;
      width: fit-content;
      height: fit-content;
      column-gap: 10px;
      .btnText {
        color: $gray2;
        text-align: right;
        font-family: $font-secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        text-transform: capitalize;
      }
      &:hover {
        .btnText {
          cursor: pointer;
          color: $blue1;
        }
        svg {
          path {
            stroke: $blue1;
          }
        }
      }
    }

    .rec1 {
      width: 46.422px;
      height: 46.422px;
      flex-shrink: 0;

      position: absolute;
      top: -46px;
      left: -47px;
      background-color: $white;
      @media (max-width: 750px) {
        display: none;
      }
    }
    .rec2 {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $white;
      z-index: 1;
      width: 658px;

      .image {
        width: 658px;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      @media (max-width: 750px) {
        width: 100%;
        height: 100%;
        .image {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }

    @media (max-width: 750px) {
      width: 100%;
      padding-inline: 26px;
      height: fit-content;
      margin-top: -1px;
      padding-bottom: 20px;
    }
  }
  .mobileBgImage {
    display: none;
  }

  // Hide background and show image on mobile
  @media (max-width: 750px) {
    background: none; /* Remove background image */
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    padding-right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
    height: fit-content;

    .mobileBgImage {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-top: 20px;
    }
  }

  // Hide the mobile image by default
}
