@import "../../globalStyles/component.scss";

.HomeSlide7 {
  display: flex;
  width: 100%;
  height: 440px;

  .left {
    width: 100%;
    padding-left: 5.6%;
    padding-right: 9.79%;
    padding-block: 79.79px;
    background-color: #3a4754;

    .upper {
      width: 100%;
      display: flex;
      column-gap: 27px;
      margin-bottom: 26px;
      .input {
        all: unset;
        width: 100%;
        display: flex;
        padding: 12px 16px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: $white;
        background-color: transparent;

        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        &::placeholder {
          color: $white;
        }
        @media (max-width: 750px) {
          width: auto;
        }
      }
      @media (max-width: 750px) {
        width: 100%;
        flex-direction: column;
        column-gap: 0;
        row-gap: 27px;
      }
    }
    .textarea {
      all: unset;
      width: calc(100% - 32px);
      height: calc(111px - 24px);
      display: flex;
      padding: 12px 16px;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $white;
      background-color: transparent;

      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      &::placeholder {
        color: $white;
      }
    }
    .btn {
      width: fit-content;
      margin-top: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: $white;
      color: $black1;

      &:hover {
        background-color: $blue1;
        color: $white;
      }
      @media (max-width: 750px) {
        width: 100%;
        padding-inline: 0;
      }
    }
    @media (max-width: 750px) {
      width: 100% !important;
      padding-inline: 31px;
      height: fit-content;
    }
  }
  .right {
    width: 100%;
    position: relative;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 450px) {
        width: fit-content;
        height: fit-content;
        transform: translateX(-50px);
        overflow: hidden;
      }
    }
    .title {
      max-width: 443px;
      color: $white;
      font-family: $font-primary;
      font-size: min(44px, 4.2vw);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      position: absolute;
      bottom: 63px;
      left: 6%;
      @media (max-width: 750px) {
        width: fit-content;
        bottom: 20px;
        font-size: min(37px, 20vw);
        height: fit-content;
      }
    }
    @media (max-width: 750px) {
      width: 100%;
      height: fit-content;
      margin-bottom: -6px;
    }
    @media (max-width: 450px) {
      width: 100%;
      height: fit-content;
      margin-bottom: -6px;
      overflow: hidden;
    }
  }

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
  }
}
