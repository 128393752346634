@import "../../globalStyles/component.scss";

.ContactUs {
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: hidden;

  .bg {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    .bgg {
      z-index: 2;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 55.78%,
        #000 113.55%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: -2px;
    }
  }
  .main {
    width: 100%;
    padding-inline: 5.8%;
    margin-bottom: 173px;
    margin-top: -66px;
    z-index: 3;
    position: relative;

    .details {
      width: 100%;
      padding-left: 8.72%;
      padding-right: 21.38%;
      background-color: #3a4754;
      padding-top: 66px;
      position: relative;
      .title {
        max-width: 449px;
        color: $white;
        font-family: $font-primary;
        font-size: min(44px, 4.2vw);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
      .left {
        position: relative;
        z-index: 3;
        width: 100%;
        padding-block: 79.79px;

        .upper {
          width: 100%;
          display: flex;
          column-gap: 27px;
          margin-bottom: 26px;
          .input {
            all: unset;
            width: 100%;
            display: flex;
            padding: 12px 16px;
            align-items: center;
            border-radius: 4px;
            border: 1px solid $white;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: $white;
            background-color: transparent;

            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            &::placeholder {
              color: $white;
            }
            @media (max-width: 750px) {
              width: auto;
            }
          }
          @media (max-width: 750px) {
            width: 100%;
            flex-direction: column;
            column-gap: 0;
            row-gap: 27px;
          }
        }
        .textarea {
          all: unset;
          width: calc(100% - 32px);
          height: calc(111px - 24px);
          display: flex;
          padding: 12px 16px;
          align-items: center;
          border-radius: 4px;
          border: 1px solid $white;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: $white;
          background-color: transparent;

          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          &::placeholder {
            color: $white;
          }
        }
        .btn {
          width: fit-content;
          margin-top: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: $white;
          color: $black1;
          @media (max-width: 750px) {
            width: 100%;
            padding-inline: 0;
          }
        }
        @media (max-width: 750px) {
          width: 100% !important;
          padding-inline: 31px;
          height: fit-content;
        }
      }
      .bg1 {
        // width: 100%;
        height: fit-content;
        position: absolute;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: 1;

        .image {
          //   width: 70%;
          width: fit-content;
          height: auto;
          object-fit: contain;
        }

        @media (max-width: 750px) {
          display: none;
        }
      }

      .rec {
        position: absolute;
        bottom: -40px;
        right: -40px;
        width: 73.61px;
        height: 73.61px;
        transform: rotate(45deg);
        background-color: $white;
        z-index: 23;
        @media (max-width: 750px) {
          display: none;
        }
      }
    }
  }
  @media (max-width: 750px) {
    display: none;
  }
}
