// color
$white: #ffffff;
$white1: #f2f2f2;
$white2: #eff7ff;
$bg: #3a4754;

$black1: #262626;
$black2: #2c2c2c;
$black3: #202020;
$black4: #2f2f2f;
$black5: #242424;

$gray1: #212121;
$gray2: #151515;
$gray3: #4d4d4d;
$gray4: #090914;
$gray5: #5c5c5c;
$gray6: #52525b;
$gray7: #1d1d1d;
$gray8: #7b828e;

$blue1: #1668e8;
$blue2: #93bdff;
$blue3: #e8f0ff;

// FONT FAMILY

$font-primary: "Roboto", sans-serif;
$font-secondary: "Montserrat", sans-serif;

// // FONT SIZE
// $font-size-logo-desktop: 3.2rem;
// $font-size-logo-mobile: 2.2rem;
// $font-size-title-text: 2.8rem;
// $font-size-secondary-title-text: 2.4rem;
// $font-size-button-text: 1.4rem;
// $font-size-primary-text: 1.6rem;
// $font-size-secondary-text: 1.4rem;
// $font-size-input-box-text: 1.4rem;
// $font-size-mobile-nav-bar-active: 1.4rem;
// $font-size-label-text: 1.2rem;
// $font-size-supporting-text: 1.2rem;
// $font-size-primary-bold: 1.6rem;

// // FONT WEIGHT
// $font-weight-logo-desktop: 500;
// $font-weight-logo-mobile: 500;
// $font-weight-title-text: 600;
// $font-weight-secondary-title-text: 600;
// $font-weight-button-text: 600;
// $font-weight-primary-text: 500;
// $font-weight-secondary-text: 500;
// $font-weight-input-box-text: 500;
// $font-weight-mobile-nav-bar-active: 600;
// $font-weight-label-text: 700;
// $font-weight-supporting-text: 400;
// $font-weight-primary-bold: 700;

// // LINE HEIGHT
// $line-height-logo-desktop: 3.8rem;
// $line-height-logo-mobile: 2.6rem;
// $line-height-title-text: 3.4rem;
// $line-height-secondary-title-text: 3rem;
// $line-height-button-text: 2rem;
// $line-height-primary-text: 2.4rem;
// $line-height-secondary-text: 2rem;
// $line-height-input-box-text: 2rem;
// $line-height-mobile-nav-bar-active: 2rem;
// $line-height-label-text: 1.6rem;
// $line-height-supporting-text: 1.6rem;
// $line-height-primary-bold: 2.4srem;
