@import "../../globalStyles/component.scss";

.Footer {
  width: 100%;
  height: fit-content;
  background-color: $blue1;
  padding-top: 44px;
  padding-bottom: 99px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    background-color: $white;
    @media (max-width: 750px) {
      display: none;
    }
  }

  .mobileLogo {
    display: none;
    @media (max-width: 750px) {
      display: block;
      background-color: $white;
    }
  }
  .listItem {
    width: fit-content;
    height: fit-content;
    display: flex;
    column-gap: 60px;
    list-style: none;
    margin-top: 79px;
    .item {
      all: unset;
      color: $white;
      font-family: $font-secondary;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 166.667% */

      &:hover {
        color: $black1;
        cursor: pointer;
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      column-gap: 0;
      row-gap: 18px;
      margin-top: 52px;
      align-items: center;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;

    .iconList {
      margin-bottom: 27px;
      margin-top: 47px;

      &:hover {
        cursor: pointer;
      }
    }
    .copyText {
      color: $white;
      text-align: center;
      font-family: $font-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
    }

    @media (max-width: 750px) {
      margin-top: 71px;
    }
  }
  @media (max-width: 750px) {
    // margin-bottom: 55px;
  }
}
