@import "../../globalStyles/component.scss";

.CheckIconWithdesc {
  width: 100%;
  //   height: fit-content;
  flex-grow: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  border: 1px solid #d0daea;

  .desc {
    color: #484a4f;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
