@import "../../globalStyles/component.scss";

.ServiceSlide3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 6.25%;
  height: fit-content;
  width: 100%;
  padding-inline: 5.8%;
  padding-bottom: 100px;
  padding-top: 100px;
  .details {
    width: 37.7%;
    height: fit-content;

    .title {
      color: $blue1;
      font-family: $font-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      margin-bottom: 22px;

      @media (max-width: 750px) {
        margin-top: 12px;
      }
    }
    .subTitle {
      margin-bottom: 44px;
      color: $gray1;
      font-family: $font-primary;
      font-size: 2.5vw;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 1150px) {
        font-size: 3.5vw;
      }
      @media (max-width: 750px) {
        font-size: 40px;
      }
    }
    .desc {
      margin-bottom: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
    }
    .btn {
      width: 250px;
      justify-content: center;
      &:hover {
        background-color: $blue1;
        color: $white;
      }
      @media (max-width: 750px) {
        width: 100%;
        padding-inline: 0;
        justify-content: center;
      }
    }

    @media (max-width: 1150px) {
      width: 50%;
    }
    @media (max-width: 750px) {
      width: 100%;
      padding-left: 25px;
      padding-right: 18px;
    }
  }
  .imageContainer {
    width: 43.4%;
    height: fit-content;
    position: relative;

    .image {
      width: 100%;
      height: fit-content;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      &:hover {
        scale: 1.02;
      }
    }
    .small {
      width: 182.976px;
      height: 146.398px;
      position: absolute;
      left: -10%;
      bottom: -80px;
      .image1 {
        width: 100%;
        height: fit-content;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      @media (max-width: 750px) {
        // right: 15px;
        left: 13px;
        bottom: -12px;
      }
    }

    @media (max-width: 750px) {
      width: 100%;
      padding-left: 34px;
      padding-right: 13px;
      margin-bottom: 79px;
    }
  }
  @media (max-width: 750px) {
    flex-direction: column-reverse;
    padding-inline: 0;
  }
}
