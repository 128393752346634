.sliderWrapper {
  width: 100%;
  height: fit-content;
  .slider {
    .cardContainer {
      padding-right: 41px;
    }
  }
}
@media (max-width: 900px) {
  .sliderWrapper {
    width: 100%;
    height: fit-content;

    .slider {
      width: 100%;
      .cardContainer {
        // width: 50vw;
        padding-right: 18px;
      }
    }
  }
}
