@import "../../globalStyles/component.scss";

.AboutSlide2 {
  width: 100%;
  height: fit-content;
  // padding-top: 114px;
  position: relative;
  overflow: hidden;
  z-index: 4;

  .bg {
    width: 100%;
    height: fit-content;
    position: absolute;
    bottom: -30vw;
    left: 0;
    overflow: hidden;
    z-index: 1;

    .image {
      width: 50%;
      height: auto;
      object-fit: contain;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }
  .main {
    width: 100%;
    height: fit-content;
    z-index: 4;
    position: relative;
    margin-top: 54px;

    .upperdiv {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      padding-inline: 5.8%;
      margin-bottom: 142px;

      .left {
        width: 42%;
        .title {
          color: $black2;
          font-family: $font-secondary;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          text-transform: uppercase;
          margin-bottom: 22px;
        }
        .subTitle {
          color: $black2;
          font-family: $font-primary;
          font-size: min(44px, 4.5vw);
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;

          @media (max-width: 750px) {
            font-size: 40px;
          }
        }
        @media (max-width: 750px) {
          width: 100%;
        }
      }
      .right {
        width: 40.7%;
        height: fit-content;
        .desc {
          color: $gray3;
          font-family: $font-primary;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          text-transform: capitalize;
          margin-bottom: 24px;
        }
        .btn {
          display: flex;
          column-gap: 10px;
          svg {
            path {
              stroke: $white;
            }
          }
          @media (max-width: 750px) {
            align-items: center;
            justify-content: center;
          }
        }
        @media (max-width: 750px) {
          width: 100%;
        }
      }
      @media (max-width: 750px) {
        flex-direction: column;
        justify-content: unset;
        padding-left: 23px;
        padding-right: 39px;
        row-gap: 28px;
      }
    }
  }
}
