@import "../../globalStyles/component.scss";

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 82px;
  padding-right: 6.11%;
  padding-left: 5.87%;
  border-bottom: 1px solid $white1;

  .logo {
    @media (max-width: 750px) {
      display: none;
    }
  }

  .menu,
  .mobileLogo {
    display: none;

    @media (max-width: 750px) {
      display: block;
    }
  }

  .menuItem {
    position: relative;
    /* Needed for absolute positioning of pop-up menu */
  }

  .listItem {
    display: flex;
    align-items: center;
    column-gap: 44px;
    list-style: none;
    padding: 0;
    margin: 0;

    .item {
      all: unset;
      color: $gray2;
      text-align: right;
      font-family: $font-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
      text-transform: capitalize;

      &:hover {
        cursor: pointer;
        color: $blue1;
      }
    }

    .popUpMenu {
      display: none;
      /* Hide by default */
      position: absolute;
      /* Position relative to the menu item */
      top: 100%;
      /* Below the item */
      left: 0;
      background-color: white;
      /* Background color of the pop-up */
      border-radius: 4px;
      /* Rounded corners */
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      /* Subtle shadow for depth */
      z-index: 1000;
      /* Ensure it appears on top */
      padding: 10px 0;
      /* Padding around the menu items */
      min-width: 150px;
      /* Minimum width for the pop-up menu */
    }

    .menuItem:hover .popUpMenu {
      display: block;
      /* Show on hover */
    }

    .popUpItem {
      display: block;
      /* Block display for each item */
      padding: 10px 20px;
      /* Padding for clickable area */
      color: #333;
      /* Text color */
      text-decoration: none;
      /* Remove underline */
      transition: background-color 0.2s ease;
      /* Smooth background transition */
    }

    .popUpItem:hover {
      background-color: #f0f0f0;
      /* Change background on hover */
      color: #000;
      /* Change text color on hover */
    }

    .chevron {
      margin: 0 5px;
      vertical-align: middle;

      width: 14px;
      height: 14px;
      fill: #333;
      /* Color of the chevron */
      transition: transform 0.2s ease;
      /* Optional: Add a transition for animation */
    }

    @media (max-width: 750px) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .btn {
    padding: 16px 26px !important;

    @media (max-width: 750px) {
      display: none;
    }
  }
}