@import "../../globalStyles/component.scss";
.HomeSlide6 {
  width: 100%;
  height: fit-content;
  padding-bottom: 101px;
  position: relative;
  overflow: hidden;
  .bg1 {
    z-index: 1;
    width: 30.55%;
    position: absolute;
    bottom: -100px;
    left: 0;
    // background-color: red;
    .image {
      width: 50%;
      height: auto;

      @media (max-width: 1440px) {
        width: 70%;
      }
    }
    @media (max-width: 750px) {
      display: none;
    }
  }
  .titleContainer {
    width: 100%;
    height: fit-content;
    padding-inline: 9.6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 54px;
    .title {
      width: 434px;
      color: $gray1;
      font-family: $font-primary;
      font-size: min(44px, 4vw);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 750px) {
        flex-direction: column;
        width: 100%;
        font-size: 38px;
      }
    }
    .btn {
      padding-inline: 26px;
      color: $white;
      text-align: right;
      font-family: $font-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      svg {
        path {
          stroke: $white;
        }
      }
      @media (max-width: 750px) {
        display: none;
      }
    }
    @media (max-width: 750px) {
      padding-inline: 0;
      flex-direction: column;
    }
  }
  .row1 {
    width: 100%;
    height: fit-content;
    padding-inline: 7.29%;
    display: flex;
    column-gap: 14px;
    margin-bottom: 14px;
    @media (max-width: 750px) {
      padding-inline: 0;
      flex-direction: column;
      column-gap: 0;
      row-gap: 12px;
    }
  }
  .row2 {
    width: 100%;
    padding-inline: 20%;
    height: fit-content;
    display: flex;
    column-gap: 14px;
    @media (max-width: 750px) {
      padding-inline: 0;
      flex-direction: column;
      column-gap: 0;
      row-gap: 12px;
    }
  }
  .btn1 {
    display: none;
    color: $white;
    text-align: right;
    font-family: $font-secondary;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    svg {
      path {
        stroke: $white;
      }
    }
    @media (max-width: 750px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      padding-inline: 0;
      width: calc(100%);
    }
  }
  @media (max-width: 750px) {
    width: 100%;
    padding-inline: 31px;
  }
}
