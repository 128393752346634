@import "../../globalStyles/component.scss";

.AboutSlide1 {
  width: 100%;
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 4;
  overflow: hidden;

  .bg1 {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 450px) {
        transform: translateX(-60%);
        width: auto;
      }
    }
  }

  .bg {
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55.78%, #000 113.55%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .detail {
    z-index: 4;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 678px;
    padding-top: 104px;

    .title {
      color: $white;
      text-align: center;
      font-family: $font-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      margin-bottom: 22px;
    }
    .subTitle {
      color: $white;
      text-align: center;
      font-family: $font-primary;
      font-size: min(44px, 4vw);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 14px;

      @media (max-width: 750px) {
        font-size: max(40px, 3vw);
      }
    }
    .desc {
      color: $white;
      text-align: center;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-transform: capitalize;
      margin-bottom: 41px;
    }
    .btn {
      background-color: $white;
      color: $black1;
      margin-bottom: 250px;
      @media (max-width: 750px) {
        width: 100%;
        padding-inline: 0;
        justify-content: center;
      }
    }
    .subTitle2 {
      color: $white;
      text-align: center;
      font-family: $font-secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      text-transform: uppercase;
      margin-bottom: 9px;
    }
    .icons {
      width: 616px;
      display: flex;
      justify-content: space-between;
      padding: 22.284px 41.494px;
      //   margin-inline: 4.5%;
      border-radius: 6.147px;
      border: 1px solid #646464;
      background: rgba(39, 39, 39, 0.35);
      backdrop-filter: blur(3.8419911861419678px);
      .image {
        width: fit-content;
        height: fit-content;
        object-fit: contain;
        @media (max-width: 750px) {
          width: auto;
          height: 11px;
        }
      }
      @media (max-width: 750px) {
        padding: 12.873px 23.97px;

        width: 100%;
        margin-inline: 0;
      }
    }

    @media (max-width: 750px) {
      width: 100%;
      padding: 12.873px 23.97px;
      overflow: hidden;
      max-width: unset;
      justify-content: center;
    }
  }
}
