@import "../../globalStyles/component.scss";

.HomeSlide5 {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background-color: $blue1;
  padding-bottom: 85.5px;

  position: relative;

  .bg {
    opacity: 0.6;
    mix-blend-mode: color-burn;
    position: absolute;
    width: 100%;

    .image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    @media (max-width: 750px) {
      height: 100%;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .rec {
    z-index: 1;
    position: absolute;
    width: 117.76px;
    height: 182.5px;
    border-radius: 0px 0px 150px 150px;
    background: #0049a1;
    display: flex;
    align-items: flex-end;
    right: 14.23%;
    top: 0;
  }
  .upperDiv {
    z-index: 4;
    position: relative;
    margin-top: 85px;
    width: 100%;
    padding-inline: 5.6%;
    .title {
      width: 553px;
      color: $white;
      font-family: $font-primary;
      font-size: min(44px, 5vw);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 24px;
      @media (max-width: 750px) {
        width: 100%;
        font-size: 44px;
      }
    }
    .desc {
      width: 553px;
      color: $white;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-transform: capitalize;
      @media (max-width: 750px) {
        width: 100%;
      }
    }

    @media (max-width: 750px) {
      padding-inline: 17px;
      // margin-top: 254px;
    }
  }
  .lowerDiv {
    margin-top: 54px;
    width: 100%;
    display: flex;
    padding-inline: 5.6%;
    column-gap: 23px;
    .imageContainer {
      width: 100%;
      position: relative;
      .image {
        width: 100%;
        height: fit-content;
        height: fit-content;
        object-fit: contain;
      }
      .title {
        position: absolute;
        bottom: 10%;
        left: 2%;
        // transform: translateY(-50%);
        color: $white;
        font-family: $font-primary;
        font-size: min(38px, 2vw);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.76px;
        @media (max-width: 750px) {
          font-size: 32px;
        }
      }
      .box {
        transition: all 0.2 ease-in-out;
        padding-inline: 16.5px;
        width: 100%;
        top: 0px;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        row-gap: 22px;

        background-color: $white;

        .singleItem {
          border-bottom: 1px solid #ff9473;

          svg {
            path {
              stroke: $white;
            }
          }

          @media (max-width: 850px) {
            padding-top: 0;
            padding-bottom: 5px;
          }
        }
        .text {
          font-size: min(18px, 1.2vw);

          @media (max-width: 750px) {
            font-size: 18px;
          }
        }
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      justify-content: center;
      .box {
        display: flex;
        justify-content: center;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    @media (max-width: 750px) {
      padding-inline: 17.5px;
      flex-direction: column;
      column-gap: 0;
      row-gap: 19px;
      margin-top: 35px;
    }
  }

  .con {
    width: 100%;
    padding-inline: 5.6%;
    display: flex;
    justify-content: center;
    .btn {
      all: unset;
      margin-top: 44px;
      display: flex;
      padding: 16px 26px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1.2px solid $white;
      z-index: 4;
      position: relative;

      color: $white;
      text-align: right;
      font-family: $font-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      &:hover {
        cursor: pointer;
        color: $blue1;
        background-color: $white;
      }

      @media (max-width: 750px) {
        width: 100%;
      }
    }
    @media (max-width: 750px) {
      padding-inline: 17.5;
    }
  }
}
