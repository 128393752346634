@import "../../globalStyles/component.scss";

.ServiceSlide1 {
  width: 100%;
  background-color: $blue1;
  padding-bottom: 105px;
  padding-top: 132px;
  position: relative;
  overflow: hidden;
  z-index: 4;
  .main {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6.25%;
    height: fit-content;
    width: 100%;
    padding-inline: 5.8%;
    padding-bottom: 50px;
    .details {
      width: 37.7%;
      height: fit-content;

      .title {
        color: $white;
        font-family: $font-secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
        margin-bottom: 22px;

        @media (max-width: 750px) {
          margin-top: 12px;
        }
      }
      .subTitle {
        margin-bottom: 14px;
        color: $white;
        font-family: $font-primary;
        font-size: 2.5vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        @media (max-width: 1150px) {
          font-size: 3.5vw;
        }
        @media (max-width: 750px) {
          font-size: 40px;
        }
      }
      .desc {
        margin-bottom: 24px;
        color: $white;
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-transform: capitalize;
      }
      .btn {
        width: fit-content;
        display: flex;
        padding: 16px 26px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #232323;
        text-align: right;
        font-family: $font-secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        background-color: $white;
        &:hover {
          background-color: $white;
          color: $blue1;
        }

        @media (max-width: 750px) {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
      @media (max-width: 1150px) {
        width: 50%;
      }
      @media (max-width: 750px) {
        width: 100%;
        padding-inline: 10px;
        // padding-bottom: 20vh;
      }
    }
    .imageContainer {
      width: 43.4%;
      height: fit-content;
      position: relative;

      .image {
        width: 100%;
        height: fit-content;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }

        &:hover {
          scale: 1.02;
        }
      }
      .small {
        width: 182.976px;
        height: 146.398px;
        position: absolute;
        left: -10%;
        bottom: -80px;
        .image1 {
          width: 100%;
          height: fit-content;
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }

        @media (max-width: 750px) {
          right: 15px;
          left: unset;
          bottom: -12px;
        }
      }

      @media (max-width: 750px) {
        width: 100%;
      }
    }
    @media (max-width: 750px) {
      flex-direction: column-reverse;
      padding-inline: 0;
    }
  }

  .bg {
    opacity: 0.6;
    mix-blend-mode: color-burn;
    position: absolute;
    width: 100%;
    z-index: 0;

    .image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    @media (max-width: 750px) {
      height: 100%;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media (max-width: 750px) {
    padding-top: 0;
  }
}
