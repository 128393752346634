@import "../../globalStyles/component.scss";

.ContactUsSlide2 {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-top: 300px;

  .main {
    overflow: hidden;
    z-index: 10;
    padding-left: 5.8%;
    padding-right: 5.6%;
    display: flex;
    align-items: stretch; // Ensures both boxes have equal height
    width: 100%;
    column-gap: 36px;
    margin-bottom: 40px;
    position: absolute;
    top: -25vh;
    left: 0;

    .box1,
    .box2 {
      width: 100%;
      padding-block: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: #3a4754;
      height: 320px;

      .value {
        margin-top: 33px;
        color: $white;
        font-family: $font-primary;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; // 128.571%
        text-transform: capitalize;
      }

      @media (max-width: 1440px) {
        height: unset;
      }
      &.selected {
        background-color: $blue1;
      }
    }

    .box1 {
      padding-left: 5%;
      padding-right: 3%;
    }

    .box2 {
      padding-left: 6%;
      padding-right: 3%;
      position: relative;
      background-color: #3a4754;
      justify-content: flex-end;

      .rec {
        position: absolute;
        top: -40px;
        right: -40px;
        width: 73.61px;
        height: 73.61px;
        transform: rotate(45deg);
        background-color: $white;
        z-index: 23;

        @media (max-width: 750px) {
          display: none;
        }
      }
    }
    @media (max-width: 1440px) {
      top: -40vh;
    }
    @media (max-width: 750px) {
      padding-inline: 0;
      column-gap: 0;
      flex-direction: column;
      margin-bottom: 0;
      position: relative;
      height: fit-content;
      top: unset;
    }
  }

  .AboutSlide4 {
    width: 100%;
    height: 668px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 4;
    overflow: hidden;

    .bg1 {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 450px) {
          transform: translateX(-30%);
          width: auto;
        }
      }
    }

    .bg {
      z-index: 6;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 55.78%,
        #000 113.55%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .detail {
      z-index: 9;
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 78px;
      width: 100%;
      height: fit-content;
      padding-inline: 6.1%;

      .title {
        padding-left: 30px;
        max-width: 555px;
        font-size: min(40px, 4vw);
        font-style: normal;
        font-weight: 300;
        line-height: 125%;
        text-transform: capitalize;
        color: $white;
        font-family: $font-primary;
        border-left: 1px solid $white;
        @media (max-width: 750px) {
          font-size: 23px;
          width: 100%;
          max-width: unset;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .item {
          display: flex;
          column-gap: 2px;
          align-items: center;
          .value {
            font-family: $font-primary;
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            text-align: left;
            color: $white;
          }
        }
      }

      @media (max-width: 750px) {
        width: 100%;
        padding-inline: 18px;
        padding-top: 47px;
        padding-bottom: 33px;
        overflow: hidden;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: unset;
        height: 100%;
        margin-bottom: 0;
      }
    }
    @media (max-width: 750px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 750px) {
    margin-top: 0;
  }
}
