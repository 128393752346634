.HomeSlider {
  width: 100%;
  position: relative;

  .slider {
    width: 100%;

    .slide {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;

      .image {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
        width: 100%; // Ensure the image takes full width
      }
    }
  }

  .pageNumbers {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    div {
      margin: 0 5px;
      cursor: pointer;

      &.active svg circle {
        stroke: #d9d9d9; // Update stroke color of the active dot
      }
    }

    @media (max-width: 750px) {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }
}
