@import "../../globalStyles/component.scss";

.Achievemnets {
  padding-top: 19px;
  padding-bottom: 38px;
  padding-right: 19px;
  padding-left: 23px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $blue1;
  height: 299px;
  position: relative;

  .upper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      background-color: #257afe;
      padding: 5px 10px;
      color: $blue2;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-transform: capitalize;

      @media (max-width: 750px) {
        font-size: 11px;
        line-height: 19px;
      }
    }
  }

  .desc {
    padding-left: 7px;
    color: #1d1d1d;
    font-family: $font-primary;
    font-size: min(24px, 2vw);
    font-style: normal;
    font-weight: 500;
    line-height: 141.667%;
    text-transform: capitalize;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: translateY(0);

    @media (max-width: 750px) {
      font-size: 19.58px;
      font-weight: 500;
      line-height: 27.74px;
      text-align: left;
    }
  }

  .hovered {
    opacity: 0;
    transform: translateY(-10px);
  }

  &:hover {
    .desc {
      opacity: 0;
      transform: translateY(-10px);
      font-weight: 300;
    }

    .desc.hovered {
      opacity: 1;
      // transform: translateY(0);
      transform: translateY(-10px);
    }
  }
}

.type {
  padding-top: 19px;
  padding-bottom: 38px;
  padding-right: 19px;
  padding-left: 23px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $blue3;
  height: 299px;
  position: relative;

  .upper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      background-color: #d5dff0;
      padding: 5px 10px;
      color: #7b828e;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 750px) {
        // font-size: 11px;
        // line-height: 19px;
      }
    }

    svg {
      path {
        stroke: #d5dff0;
      }
    }
  }

  .desc {
    color: $gray7;
    transition: all 0.3 ease-in-out;

    &:hover {
      transform: translateY(-10px);
      color: $white;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $blue1;

    .upper {
      .title {
        background-color: #257afe;
        color: $blue2;
      }

      svg {
        path {
          stroke: #d5dff0;
        }
      }
    }

    .desc {
      color: $white;
    }
  }
}
