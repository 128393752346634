@import "../../globalStyles/component.scss";
.IconWithDesc {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  //   column-gap: 24px;
  justify-content: space-between;
  padding-top: 10px;
  padding-inline: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
  .desc {
    color: #909090;
    font-family: $font-primary;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }
  svg {
    path {
      stroke: #909090;
    }
  }
}
.active {
  width: 100%;
  height: fit-content;
  display: flex;
  column-gap: 24px;
  padding-top: 10px;
  padding-inline: 10px;
  padding-bottom: 20px;
  // border-bottom: 1px solid #e4e4e4;
  .desc {
    color: #0075ff;
    font-family: $font-primary;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }
  svg {
    path {
      stroke: #0075ff;
    }
  }
}

.active,
.IconWithDesc:hover {
  cursor: pointer;
}
