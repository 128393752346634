@import "../../globalStyles/component.scss";

.ServicesBox {
  padding-inline: 43px;
  padding-block: 66px;
  border-radius: 6px;
  border: 2px solid #c7d4e2;
  background: #f2f5f9;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 550px;
  flex-grow: 1;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 704px;

  .title {
    color: $gray1;
    font-family: $font-primary;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 16px;
    @media (max-width: 750px) {
      font-size: 23.314px;
      margin-bottom: 10px;
    }
  }

  .desc {
    color: $gray3;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    margin-bottom: 44px;
    @media (max-width: 750px) {
      font-size: 10.971px;
      line-height: 16.457px;
      margin-bottom: 30px;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }

  .btn {
    margin-top: 46px;
    width: 250px;
    justify-content: center;
    @media (max-width: 750px) {
      width: 100%;
      padding-inline: 0;
      justify-content: center;
      margin-top: 31px;
    }
  }

  @media (max-width: 750px) {
    padding-inline: 30px;
    padding-block: 45px;
    max-width: unset;
    height: fit-content;
    width: 100%;
  }
}
