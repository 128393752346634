@import "../../globalStyles/component.scss";

.AboutSlide3 {
  width: 100%;
  height: fit-content;
  padding-inline: 11.66%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 139px;
  margin-top: 64px;

  .imageContainer {
    width: 40%;
    height: fit-content;
    .image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    @media (max-width: 750px) {
      width: 100%;
    }
  }
  .details {
    width: 50%;
    height: fit-content;
    .dec {
      width: 100%;
      height: fit-content;
      padding-bottom: 33px;
      border-bottom: 1px solid #dfdfdf;
      color: $black3;
      font-family: $font-primary;
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
      line-height: 38px; /* 135.714% */
      text-transform: capitalize;

      .highlit {
        font-weight: 600;
      }
      @media (max-width: 750px) {
        font-size: 20px;
        line-height: 28.69px;
      }
    }
    .titles {
      margin-top: 33px;
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      column-gap: 9px;
      .name {
        color: #2f2f2f;
        font-family: $font-primary;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 100% */
        text-transform: capitalize;
        text-wrap: nowrap;
        @media (max-width: 750px) {
          font-size: 18px;
          line-height: 18.69px;
        }
      }
      .position {
        color: #2f2f2f;
        font-family: $font-primary;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        text-transform: capitalize;
        text-wrap: nowrap;
        @media (max-width: 750px) {
          font-size: 18px;
          line-height: 18.69px;
        }
      }
    }
    .logo {
      @media (max-width: 750px) {
        display: none;
      }
    }

    .mobileLogo {
      display: none;
      @media (max-width: 750px) {
        display: block;
        background-color: $white;
      }
    }
    @media (max-width: 750px) {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    padding-inline: 15.69px;
  }
  @media (max-width: 750px) {
    flex-direction: column;
    row-gap: 47px;
    padding-inline: 15.69px;
  }
}
